// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blanqueamiento-dental-jsx": () => import("/opt/build/repo/src/pages/blanqueamiento-dental.jsx" /* webpackChunkName: "component---src-pages-blanqueamiento-dental-jsx" */),
  "component---src-pages-cirugia-oral-y-maxilofacial-jsx": () => import("/opt/build/repo/src/pages/cirugia-oral-y-maxilofacial.jsx" /* webpackChunkName: "component---src-pages-cirugia-oral-y-maxilofacial-jsx" */),
  "component---src-pages-endodoncia-jsx": () => import("/opt/build/repo/src/pages/endodoncia.jsx" /* webpackChunkName: "component---src-pages-endodoncia-jsx" */),
  "component---src-pages-especialidades-jsx": () => import("/opt/build/repo/src/pages/especialidades.jsx" /* webpackChunkName: "component---src-pages-especialidades-jsx" */),
  "component---src-pages-implantes-dentales-jsx": () => import("/opt/build/repo/src/pages/implantes-dentales.jsx" /* webpackChunkName: "component---src-pages-implantes-dentales-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nuestro-centro-jsx": () => import("/opt/build/repo/src/pages/nuestro-centro.jsx" /* webpackChunkName: "component---src-pages-nuestro-centro-jsx" */),
  "component---src-pages-odontopediatria-jsx": () => import("/opt/build/repo/src/pages/odontopediatria.jsx" /* webpackChunkName: "component---src-pages-odontopediatria-jsx" */),
  "component---src-pages-ortodoncia-y-ortopedia-jsx": () => import("/opt/build/repo/src/pages/ortodoncia-y-ortopedia.jsx" /* webpackChunkName: "component---src-pages-ortodoncia-y-ortopedia-jsx" */),
  "component---src-pages-periodoncia-jsx": () => import("/opt/build/repo/src/pages/periodoncia.jsx" /* webpackChunkName: "component---src-pages-periodoncia-jsx" */),
  "component---src-pages-por-que-elegirnos-jsx": () => import("/opt/build/repo/src/pages/por-que-elegirnos.jsx" /* webpackChunkName: "component---src-pages-por-que-elegirnos-jsx" */),
  "component---src-pages-protesis-dentales-jsx": () => import("/opt/build/repo/src/pages/protesis-dentales.jsx" /* webpackChunkName: "component---src-pages-protesis-dentales-jsx" */),
  "component---src-pages-rellenos-oseos-jsx": () => import("/opt/build/repo/src/pages/rellenos-oseos.jsx" /* webpackChunkName: "component---src-pages-rellenos-oseos-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

